import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,3,5];

export const dictionary = {
		"/": [~6],
		"/(default)/account": [~7,[2]],
		"/(default)/admin": [~8,[2]],
		"/auth/callback/confirm": [15],
		"/auth/callback/recovery": [16],
		"/(default)/how-it-works": [9,[2]],
		"/(default)/saved-grants": [~10,[2]],
		"/(default)/scoutcapture": [~11,[2]],
		"/(default)/search": [~12,[2]],
		"/(default)/search/result/compliance-matrix/[grant_id]": [~14,[2]],
		"/(default)/search/result/[slug]": [~13,[2]],
		"/workspace": [~17,[3],[4]],
		"/workspace/content-library": [~20,[3],[4]],
		"/workspace/overview": [~21,[3],[4]],
		"/workspace/task-tracker": [~22,[3],[4]],
		"/workspace/(drafts)/[application_id]/edit": [~19,[3,5],[4]],
		"/workspace/(drafts)/[application_id]/[question_id]/edit": [~18,[3,5],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';